import { withRedux } from '../components/hoc/withRedux';
import { Fragment } from 'react';
import LoaderWindow from '../components/LoaderWindow';
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { initialState } from '../store/reducers/bootstrap';

const RedirectPage = () => {
    const langUrl = useSelector(state => state.language.language);

    useEffect(() => {
        window.location.replace(`/${langUrl}/`)
    });

    return (
        <Fragment>
            <LoaderWindow loadingProgress={initialState.loadingProgress} />
        </Fragment>
    )
};

export default withRedux(RedirectPage);
